import React, { useState,useEffect,useRef } from 'react';
import { graphql } from "gatsby"
import _ from 'lodash'
import tw from 'twin.macro'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A, H1 } from '../components/styles'
import Layout from "../components/layout"
import Group from "../components/group"


export const pageQuery = graphql`
 query {
    allStrapiWorkDetails(sort: { fields: [created_at], order: DESC}){
        edges {
         node {
            created_at
           seo {
            shareImage {
                localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
           }
           title
           tooltip
           hot
           recommend
           work_categories {
                title
            }
           link {
            url
           }
         }
       }
      }
      allStrapiWorkCategories {
        edges {
                node {
                    title
                    type
                    link {
                        url
                    }
                }
            }
        }

  allStrapiWork{
    edges {
     node {
        seo {
         metaTitle
         metaDescription
         shareImage {
             url
         }
       }
       title
       work_categories {
         title
         link {
            url
         }
       } 
     }
   }
  }
 }`
 
export default function IndexPage({location,data}) {
    const node = data.allStrapiWork.edges[0].node
    const workArr = data.allStrapiWorkDetails.edges
    const listWorkData = data.allStrapiWorkCategories.edges
    const [navVisible,setNavVisible] = useState(false)
    const [navIndex,setNavIndex] = useState()
    const refList = useRef(null)
    const styles = {
        Li:[
            tw`relative pl-5 text-xl font-bold`,
            tw`after:content-none after:w-2 after:h-2 after:border after:border-white after:rounded-full after:absolute after:left-0 after:top-2.5`,
            tw`hover:after:bg-white`
        ]
    }
    let listWorkArr = listWorkData.map(({node})=>{
        return {title:node.title,url:node.link.url,type:node.type}
    })
    let listArr = []
    let listObj = _.groupBy(listWorkArr, 'type')
    for(let i in listObj){
        listArr.push(listObj[i])
    }
    useEffect(()=>{
        document.addEventListener("click",()=>{
            setNavVisible(false)
            setNavIndex(null)
        })
    },[])
    const showMore = (e)=>{
        e.preventDefault()
        let listDom = refList.current.querySelectorAll(".hidden")
        const listCount = 14
        for(let i=0;i<listDom.length;i++){
            if(i<listCount){
                listDom[i].classList.remove("hidden")
            }
        }
        if(listDom.length<=listCount){
            e.target.classList.add("opacity-30")
            e.target.classList.add("cursor-default")
        }
    }
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pb-[200px] lg:pb-[120px]">
                <h1 data-aos="fade-up" data-aos-duration="600" className="text-[200px] leading-none pb-[95px] lg:pb-[50px] sm:pb-10 pt-[260px] lg:pt-[165px] xl:text-[150px] lg:text-[72px]">{node.title}</h1>
                <div>
                    <ul className="text-[28px] sm:text-[22px] pb-6 font-bold text-[#bbb] flex items-center flex-wrap md:flex-col md:items-start">
                        <li><a href="/work" className="hover:text-black text-black">All</a></li>
                        {
                            node.work_categories.map((n,i)=>{
                                return <li key={i} onClick={(e)=>{setNavVisible(true);e.nativeEvent.stopImmediatePropagation();setNavIndex(i)}} className={`hover:text-black hover:cursor-pointer ml-8 md:ml-0 ${navIndex==i?'text-black':''}`}>{n.title}</li>
                            })
                        }
                    </ul>
                    <div onClick={(e)=>{e.nativeEvent.stopImmediatePropagation();}} className={`bg-black w-full h-screen text-white absolute left-0 z-[2] ${navVisible?'':'hidden'}`}>
                        <div className="container mx-auto w-full relative">
                            <span className="absolute right-8 -top-[58px] hover:cursor-pointer" onClick={()=>{setNavVisible(false);setNavIndex(null)}}><svg width="24" height="24" fillRule="evenodd"><path d="m4.6 3 17 17-2.2 2-17-17z"/><path d="m2.5 20 17-17 2 2-17 17z"/></svg></span>
                            {
                                listArr.map((n,i)=>{
                                    return (
                                        <ul key={i} className={`grid grid-cols-4 lg:grid-cols-2 xs:grid-cols-1 gap-4 pt-10 pb-20 ${navIndex==i?'':'hidden'}`}>
                                            {
                                                n.map((e,k)=>{
                                                    var f = true
                                                    node.work_categories.forEach(p => {
                                                        if(p.title==e.title){
                                                            f = false
                                                        }
                                                    });
                                                    if(f){
                                                        return <li css={styles.Li}><a href={e.url}>{e.title}</a></li>
                                                    }
                                                })
                                            }
                                        </ul>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="works-block list" ref={refList}>
                    {
                        workArr.map(({node},i)=>{
                            if(node.hot){
                                return (
                                    <Group dataAosDuration={600+i*400} cl={`${i>13?'hidden':''}`} key={i} node={node}/>
                                )
                            }
                        })
                    }
                    </div>
                    <A href="#" variant="add" onClick={showMore}>Show more work</A>
                </div>
            </div>
        </Layout>
    )
}

